import React, { useState, useEffect, useContext, useCallback } from 'react';
import { navigate, PageProps } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import { LayoutMissionDesktop, LayoutMissionMobile } from '@components';
import { CreateGoalMobile } from '@components/Structural/Goal/CreateGoalMobile';
import { CreateGoalDesktop } from '@components/Structural/Goal/CreateGoal';
import CreateGoalStep1 from '@components/Structural/Goal/CreateGoalStep1';
import CreateGoalStep2 from '@components/Structural/Goal/CreateGoalStep2';
import { useAuth } from '@hooks';
import { AppContext, GoalsContext } from '@context';
import { Create } from 'images/Goal';
import { updateUser, completeMissionMutation, getUserMissions } from '@apollo';
import { useApolloClient } from '@apollo/client';
import { MissionsInfo } from '@data';
import { nextMissionModalInfo } from '@utils';
import { Route } from '@interfaces';

const M5Goal: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery('(min-width: 1240px)');
  const { addGoal, updateGoal } = useContext(GoalsContext);
  const { openCongratsModal } = useContext(AppContext);
  const [stepNumber, setStepNumber] = useState<number>(0);
  const [sliderValue, setSliderValue] = useState<number>(0); //period!!
  const [goalValue, setGoalValue] = useState<number>(0);
  const [goalName, setGoalName] = useState<string>(undefined);
  const [investmentFrequency, setInvestmentFrequency] =
    useState<string>(undefined);
  const [goalType, setGoalType] = useState<string>(undefined);
  const [goalMonthlyInv, setGoalMonthlyInv] = useState<number>(0);
  const [depositValue, setDepositValue] = useState<number>(0);
  const [firstFundSelection, setFirstFundSelection] =
    useState<string>(undefined);

  const { userMissions } = getUserMissions(); //!! to update mission
  const client = useApolloClient(); //!! to update mission
  const categoryMissions = userMissions.filter(
    (obj) => obj.mission.category == MissionsInfo.ProfileCategory.name
  );
  const currentMission = categoryMissions.filter(
    (obj) => obj.mission.id == MissionsInfo.ProfileCategory.m5.id
  )[0];
  const [confettiFire, setConfettiFire] = useState<boolean>(false);

  const handleClick = () => {
    // if (currentMission?.state == "PENDING") {
    try {
      completeMissionMutation(client, currentMission.mission.id);
      setConfettiFire(true);
      openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    } catch (error) {
      console.log(error);
    }
    // } else {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // }
  };

  useEffect(() => {
    // if (currentMission?.state == "COMPLETED") {
    //   setConfettiFire(true);
    //   openCongratsModal(nextMissionModalInfo(currentMission, categoryMissions));
    // } else {
    //   setConfettiFire(false);
    // }
    setConfettiFire(false);
  }, [currentMission]);

  useEffect(() => {
    setStepNumber(0);
  }, []);

  useEffect(() => {
    if (stepNumber == 2) {
      if (goalName && goalValue && sliderValue) {
        addGoal({
          name: goalName,
          goal: goalValue,
          period: sliderValue,
          investmentFrequency: investmentFrequency,
          type: goalType,
          monthlyInvestment: goalMonthlyInv,
        });
        handleClick();
      }
    }
  }, [stepNumber]);

  useEffect(() => {
    if (sliderValue < 1) {
      setFirstFundSelection('Conservador');
    }
    if (sliderValue >= 1 && sliderValue < 5) {
      setFirstFundSelection('Moderado');
    }
    if (sliderValue > 4) {
      setFirstFundSelection('Agresivo');
    }
  }, [sliderValue]);

  const onHouseClick = useCallback(() => {
    navigate(Route.completeProfile);
  }, []);
  return (
    <>
      <SEO />
      {isMobile && (
        <>
          <LayoutMissionDesktop
            title={'Crea un objetivo'}
            subtitle={'Crea un objetivo para tus inversiones'}
            confettiFire={confettiFire}
            number={5}
            onHouseClick={onHouseClick}
          >
            {stepNumber === 0 && (
              <CreateGoalDesktop
                title="Crear un objetivo nuevo"
                icon={Create}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                goalName={goalName}
                setGoalName={setGoalName}
                setStepNumber={setStepNumber}
                setGoalType={setGoalType}
              />
            )}
            {stepNumber === 1 && (
              <CreateGoalStep1
                icon={Create}
                title={'Paso 2'}
                setStepNumber={setStepNumber}
                setGoalValue={setGoalValue}
                goalValue={goalValue}
                setGoalMonthlyInv={setGoalMonthlyInv}
                goalMonthlyInv={goalMonthlyInv}
                setDepositValue={setDepositValue}
                sliderValue={sliderValue}
                depositValue={depositValue}
                setFirstFundSelection={setFirstFundSelection}
                firstFundSelection={firstFundSelection}
              />
            )}
            {stepNumber === 2 && (
              <CreateGoalStep2
                nameGoal={goalName}
                term={sliderValue}
                goal={goalValue}
                depositValue={depositValue}
                firstFundSelection={firstFundSelection}
              />
            )}
          </LayoutMissionDesktop>
        </>
      )}
      {!isMobile && (
        <>
          <LayoutMissionMobile
            title={'Crea un objetivo'}
            subtitle={'Crea un objetivo para tus inversiones'}
            confettiFire={confettiFire}
            number={5}
            onHouseClick={() => navigate(Route.completeProfile)}
          >
            {stepNumber === 0 && (
              <CreateGoalMobile
                title="Crear un objetivo nuevo"
                icon={Create}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                goalName={goalName}
                setGoalName={setGoalName}
                setStepNumber={setStepNumber}
                setGoalType={setGoalType}
              />
            )}
            {stepNumber === 1 && (
              <CreateGoalStep1
                icon={Create}
                title={'Paso 2'}
                setStepNumber={setStepNumber}
                setGoalValue={setGoalValue}
                goalValue={goalValue}
                setGoalMonthlyInv={setGoalMonthlyInv}
                goalMonthlyInv={goalMonthlyInv}
                setDepositValue={setDepositValue}
                sliderValue={sliderValue}
                depositValue={depositValue}
                setFirstFundSelection={setFirstFundSelection}
                firstFundSelection={firstFundSelection}
              />
            )}
            {stepNumber === 2 && (
              <CreateGoalStep2
                nameGoal={goalName}
                term={sliderValue}
                goal={goalValue}
                depositValue={depositValue}
                firstFundSelection={firstFundSelection}
              />
            )}
          </LayoutMissionMobile>
        </>
      )}
    </>
  );
};

export default M5Goal;
